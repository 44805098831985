<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container>
        <v-row>
          <v-col md="12">
            <v-btn
              color="secondary"
              dark
              @click="backToShop"
              style="float: right; margin-top: -20px; margin-bottom:15px; border-radius: 0px 0px 0px 0px;"
            >
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="black">
                    mdi-store
                  </v-icon>
                  <v-icon v-bind="attrs" v-on="on" color="black">
                    mdi-undo-variant
                  </v-icon>
                </template>
                <span> Back to Shopping</span>
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-card class="mx-auto my-n9" max-width="600">
        <material-card
          icon="mdi-receipt"
          icon-small
          color="secondary"
          :title="`Bill No : ` + storeBillInfo.billNo"
          :subtitle="`Created On: ` + dateFormat(storeBillInfo.createdAt)"
        >
          <v-col class="mx-auto" style="margin-top: -40px" md="9" sm="12">
            <v-list-item align="center">
              <v-list-item-title class="">
                <a href="https://www.softclient.com.au/">
                  <v-list-item-avatar size="100">
                    <v-img :src="require('@/assets/companylogo.jpg')" />
                  </v-list-item-avatar>
                </a>
              </v-list-item-title>
            </v-list-item>
          </v-col>

          <v-card-text>
            <div class="comapny-name">
              {{ companyInfo.companyName }}
            </div>

            <div class="company-detail">ABN : {{ companyInfo.abn }}</div>

            <div class="company-detail">
              <p><span v-html="companyInfo.address"></span></p>
            </div>
            <div class="company-detail">
               <v-icon small dense class="mt-n1">mdi-phone-classic</v-icon>
              {{ companyInfo.phoneNumber }}
            </div>
          </v-card-text>
          <v-card-text>
            <div class="order-type-text">
              {{ orderTypeText }}
            </div>
            <!-- Order Type : {{ this.orderTypeText }} -->
            <v-container>
              <v-row>
                <v-col
                  style="background-color:whitesmoke"
                  class="bill-details-info"
                >
                  <v-card-text class="mb-n5">
                    <v-row style="font-size:18px; color: black">
                      <v-col cols="6">
                        <div>
                          SUB TOTAL
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div>
                          :
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="float-right">
                          $ {{ formatPrice(this.storeBillInfo.billTotal) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text class="mb-n5">
                    <v-row style=" font-size:18px; color: black">
                      <v-col cols="6">
                        <div>
                          DELIVERY 
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div>
                          :
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="float-right">
                          $ {{ formatPrice(this.storeBillInfo.riderAmount) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text class="mb-n5">
                    <v-row style=" font-size:18px; color: black">
                      <v-col cols="6">
                        <div>
                          EXTRA CHARGE
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div>
                          :
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="float-right">
                           $ {{ formatPrice(this.storeBillInfo.extraCharges) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text>
                    <v-row
                      style="font-size:18px; font-weight: bold ; color: primary; text-transform:uppercase"
                    >
                      <v-col cols="6">
                        <div>
                          Net Total
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div>
                          :
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="float-right">
                          $ {{ formatPrice(this.storeBillInfo.billTotal) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text></v-col
                >
              </v-row>

              <v-card-text>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="storeBillInfo.billProducts"
                    hide-default-footer
                    style="color:black"
                  >
                    

                    <template v-slot:[`item.amount`]="{ item }">
                      {{ formatPrice(item.amount) }}
                    </template>

                    <template v-slot:[`item.actualPrice`]="{ item }">
                      {{ formatPrice(item.actualPrice) }}
                    </template>
                  </v-data-table>

                  
                </v-col>
              </v-card-text>
              <v-row>
                <v-col class="qr-code-info">
                  <v-card-text class="mb-n5">
                    <qrcode-vue
                      style="float:right"
                      v-if="storeBillInfo.billNo"
                      render-as="svg"
                      :value="storeBillInfo.billNo"
                      size="80"
                      level="H"
                    />
                  </v-card-text>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </material-card>
      </v-card>
    </v-card-text>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import QrcodeVue from "qrcode.vue";
import Vue from "vue";
Vue.component("qrcode-vue", QrcodeVue);

import MaterialCard from "../components/MaterialCard.vue";

export default {
  components: { MaterialCard, Loading },

  data() {
    return {
      cartGst: 0,
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,
      orderTypeText: "",
      orderBill: {
        customerId: 0,
        customerName: "",
        custCode: "",
        products: [
          {
            productId: 0,
            quantity: 0,
            instructions: "",
            orderType: 0,
            sizeId: 0,
            addonItems: [],
            ingredients: []
          }
        ],
        change: 0,
        cash: 0,
        card: 0,
        voucher: 0,
        accounts: 0,
        cheque: 0,
        deliveryAddress: "",
        deliveryPostCode: "",
        deliveryContactNo: "",
        isWebOrder: true,
        paidAmount: 0,
        deliveryBoyName: "",
        riderAmount: 0,
        tokenNo: "",
        tabID: "",
        discountReason: "",
        discountTotal: 0,
        orderType: 0
      },

      viewCart: false,
      placeOrderView: false,
      headers: [
        //  { text: "", value: "image" },
        { text: "Item", value: "productDescription" },
        // { text: "Addons", value: "addonItemsName" },
        { text: "QTY", value: "quantity" },
        { text: "Unit", value: "amount" },
        { text: "Price", value: "actualPrice" }
        // { text: "", value: "delete" }
      ],
      dialogDelete: false,
      editedItem: {
        itemName: "",
        unitPrice: "",
        quantity: "",
        cost: ""
      },
      discount: 0,
      gst: 0,
      bills: {},
      billNo: "",
      orderCartData: [],
      finalBill: [],
      companyInfo: {},
      storeBillInfo: []
    };
  },

  computed: {
    ...mapState(["cartData"]),
    ...mapState(["cartNetCost"]),

    ...mapState(["orderType"]),
    ...mapState(["cartBillNo"])
    // ...mapState(["storeBillInfo"])
  },

  created() {
    console.log("Cart Bill", window.localStorage.getItem("localBillInfo"));
    console.log("local Bill", window.localStorage.getItem("localBillInfo"));

    this.storeBillInfo = JSON.parse(
      window.localStorage.getItem("localBillInfo")
    );

    this.cartNetCostFinal = window.localStorage.getItem("cartNetCostStore");

    console.log("this.storeBillInfo", this.storeBillInfo);

    console.log("this.orderType", this.orderType);
    if (this.orderType == 0) {
      this.orderTypeText = "Pick Up";
    }
    if (this.orderType == 1) {
      this.orderTypeText = "Delivery";
    }
    if (this.orderType == 2) {
      this.orderTypeText = "Dine In";
    }

    // console.log("cart", this.cartData);
    //  console.log("bill", this.cartBillNo);
    //  console.log("billInfo", this.storeBillInfo);
    this.cartGst = this.storeBillInfo.billTotal / 10;
    this.$http.get("/Licensee/CompanyInfo").then(response => {
      this.companyInfo = response.data;
      //   console.log(response);
    });
  },
  methods: {
        dateFormat(val) {
      return moment.utc(val).local().format("DD/MM/YYYY h:mm:ss a");
    },
    ...mapMutations(["UPDATE_NET_COST"]),

    getBillNo() {
      this.isLoading = true;
      this.$http.get("/BillHeader").then(response => {
        this.bills = response.data.data;
        var lastBillNo = Math.max.apply(
          Math,
          this.bills.map(function(o) {
            return o.billNo;
          })
        );

        if (this.bills.length == 0) {
          this.billNo = 1;
        } else {
          this.billNo = lastBillNo + 1;
        }
        this.isLoading = false;
        this.placeOrder();
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    backToShop() {
      //  console.log("back");
      this.cartData.splice(0, this.cartData.length);

      this.UPDATE_NET_COST(0);
      this.$router.push("/");
      window.localStorage.clear();
    }
  }
};
</script>

<style>
.comapny-name {
  padding: 5px;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
  font-size: 30px;
  color: black;
  font-weight: bold;
}

.company-detail {
  padding: 2px;
  text-align: center;
  font-size: 15px;
  color: black;
}
.order-type-text {
  padding: 5px;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
}
</style>
